import React, { useEffect, useLayoutEffect } from 'react'
import { Link, navigate } from 'gatsby'
import { graphql } from 'gatsby'
import urlSlug from 'url-slug'
import Layout from '../../components/layout'
import Seo from '../../components/seo'

import Pager from '../../components/pager.js'
import ReactMarkdown from 'react-markdown'
import { getDateFormatted } from '../../helpers/getDateFormatted'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import pictureFeedSchedule from '../../images/picture-schedule.jpg'
import Carousels from '../../components/carousel'
import { useForm } from '../../hooks/useForm'
import { useWindowSize } from '../../hooks/useWindowSize'
import { DIARY_GENERAL_ROUTE } from '../../utils/variables/generalRoutes'

const eventList = ({ data, pageContext }) => {
  const {
    allStrapiDiary: { nodes: diaries },
    strapiInstitution: institution
  } = data
  const { mainNavbar, title, diariesCategoriesData, category } = pageContext

  const [valuesFilter, handleInputChange] = useForm({
    categorySelected: category
  })

  const objCarousel = {
    visible: true,
    slide: institution.mainCover?.diariesCover
  }

  const { categorySelected } = valuesFilter

  const [width] = useWindowSize()

  const isHomeUcc = false
  const page = {
    slug: null,
    ...mainNavbar
  }

  useEffect(() => {
    if (categorySelected.toLowerCase() !== category.toLowerCase()) {
      if (categorySelected.toLowerCase() === 'todas') {
        return navigate(`/${DIARY_GENERAL_ROUTE}/`, { replace: false })
      }
      navigate(`/${DIARY_GENERAL_ROUTE}/${urlSlug(categorySelected)}`, {
        replace: true
      })
    }
  }, [categorySelected])

  useLayoutEffect(() => {
    if (width < 768) {
      return window.scrollTo({ top: 265, behavior: 'smooth' })
    } else if (width < 1400) {
      return window.scrollTo({ top: 270, behavior: 'smooth' })
    } else if (width < 1600) {
      return window.scrollTo({ top: 304, behavior: 'smooth' })
    } else {
      return window.scrollTo({ top: 370, behavior: 'smooth' })
    }
  }, [width])

  return (
    <Layout page={page} logo={page.logo}>
      <Seo title={`Agenda ${title}`} />
      <section className="schedule-feed mb-3">
        {objCarousel?.slide && objCarousel?.slide.length > 0  ? (
          <div className="d-flex flex-column schedule-header-carousel">
            <Carousels carousel={objCarousel} keyName={'scheduleFeed-'} />
          </div>
        ) : null}

        <div className="container">
          <div className="my-5">
            <div className="d-flex align-items-center justify-content-center row mb-4">
              <div className="col-12 col-md-8 row mb-auto fix-ml-negative">
                <h5 className="title-secondary text-uppercase">
                  Agenda <span className="font-weight-bold text-main">UCC</span>
                </h5>
              </div>
              <form className=" col-12 col-md-4 ml-auto">
                <div className="d-flex feed-form">
                  <select
                    className="form-control form-control-sm"
                    onChange={handleInputChange}
                    name="categorySelected"
                    defaultValue={categorySelected}
                  >
                    <option value="-1" disabled>
                      Por Categoría
                    </option>
                    <option value="Todas">Todas</option>
                    {diariesCategoriesData.map((category) => (
                      <option value={category.name} key={category.strapiId}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            </div>
            <div className=" row">
              {diaries.length < 1 ? (
                <h4 className="mt-5 col">
                  No se encontraron agendas de la UCC con la categoría
                  {` "${categorySelected}"`}
                </h4>
              ) : (
                diaries.map((diary) => {
                  const objDate = getDateFormatted(new Date(diary.date))
                  return (
                    <div
                      className={
                        'col-12 col-xl-3 col-lg-4 col-md-6 col-sm-6 pb-4 mb-3 d-flex align-items-stretch'
                      }
                      key={diary.strapiId}
                    >
                      <div
                        key={`diaresId-${diary.strapiId}`}
                        className={
                          'schedule-card-wrapper mx-2 p-4 h-100 rounded shadow-sm bg-white'
                        }
                        style={{ minHeight: 292 + 'px' }}
                      >
                        <Link
                          to={`/${DIARY_GENERAL_ROUTE}/${diary?.slug}`}
                          className="text-decoration-none"
                        >
                          <div className="text-center text-uppercase d-flex flex-column align-items-center justify-content-center py-2 border-bottom">
                            <h6
                              className={`font-weight-bold ${
                                isHomeUcc ? 'ucc-text-blue' : ' '
                              }`}
                            >
                              {objDate.day}
                            </h6>
                            <h1
                              className={`font-weight-bold ${
                                isHomeUcc ? 'ucc-text-blue' : ' '
                              }`}
                            >
                              {objDate.numberDay}
                            </h1>
                            <h6
                              className={`font-weight-bold ${
                                isHomeUcc ? 'ucc-text-blue' : ' '
                              }`}
                            >
                              {objDate.mounth}
                            </h6>
                          </div>
                          <div className="h-50 text-uppercase d-flex flex-column align-items-stretch justify-content-between pt-2">
                            <ReactMarkdown
                              escapeHtml={false}
                              source={diary.title}
                              className={`font-weight-bold ${
                                isHomeUcc ? 'ucc-text-blue' : ' '
                              }`}
                            />
                            <h6>
                              <FontAwesomeIcon
                                icon={['fas', 'map-marker-alt']}
                                size="lg"
                                className={`mr-1 ${
                                  isHomeUcc ? 'ucc-text-blue' : ''
                                }`}
                              />
                              <span
                                className={`font-weight-bold text-capitalize ${
                                  isHomeUcc ? 'ucc-text-blue' : ''
                                }`}
                              >
                                {diary.place}
                              </span>
                            </h6>
                          </div>
                        </Link>
                      </div>
                    </div>
                  )
                })
              )}
              <Pager pageContext={pageContext} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default eventList

export const eventListQuery = graphql`
  query($skip: Int, $limit: Int, $date: Date, $category: String) {
    allStrapiDiary(
      skip: $skip
      limit: $limit
      sort: { fields: date, order: ASC }
      filter: {
        date: { gte: $date }
        diary_category: { name: { eq: $category } }
      }
    ) {
      nodes {
        strapiId
        title
        slug
        date(formatString: "YYYY/MM/DD", locale: "es")
        time
        place
        diary_category {
          name
        }
      }
    }
    strapiInstitution {
      mainCover {
        diariesCover {
          content
          imageDescription
          name
          visible
          url
          backgroundImage {
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
      }
    }
  }
`
