export function getDateFormatted(date) {
  let dateString = date.toString()
  let day
  let mounth
  var numberDay = dateString.substr(8, 2)

  switch (dateString.substr(0, 3)) {
    case 'Mon':
      day = 'Lun'
      break
    case 'Tue':
      day = 'Mar'
      break
    case 'Wed':
      day = 'Mie'
      break
    case 'Thu':
      day = 'Jue'
      break
    case 'Fri':
      day = 'Vie'
      break
    case 'Sat':
      day = 'Sábado'
      break
    case 'Sun':
      day = 'Domingo'
      break
    default:
      break
  }

  switch (dateString.substr(4, 3)) {
    case 'Jan':
      mounth = 'Enero'
      break
    case 'Feb':
      mounth = 'Febrero'
      break
    case 'Mar':
      mounth = 'Marzo'
      break
    case 'Apr':
      mounth = 'Abril'
      break
    case 'May':
      mounth = 'Mayo'
      break
    case 'Jun':
      mounth = 'Junio'
      break
    case 'Jul':
      mounth = 'Julio'
      break
    case 'Aug':
      mounth = 'Agosto'
      break
    case 'Sep':
      mounth = 'Septiembre'
      break
    case 'Oct':
      mounth = 'Octubre'
      break
    case 'Nov':
      mounth = 'Noviembre'
      break
    case 'Dec':
      mounth = 'Diciembre'
      break
    default:
      break
  }

  return {
    day: day,
    mounth: mounth,
    numberDay: numberDay
  }
}
