import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const Pager = ({ pageContext }) => {
  const {
    previousPagePath,
    nextPagePath,
    humanPageNumber,
    numberOfPages
  } = pageContext

  return (
    <>
      {numberOfPages > 1 ? (
        <div className="w-100 d-flex align-items-center justify-content-between border-top pt-3 ">
          <div className="flex-even d-flex justify-content-start">
            {previousPagePath ? (
              <Link
                className="btn btn-sm btn-outline-dark fit-content"
                to={`/${previousPagePath}`}
              >
                {' '}
                Anterior{' '}
              </Link>
            ) : null}
          </div>
          <div className="flex-grow-1 d-flex justify-content-center">
            {numberOfPages > 1 ? (
              <p className="mb-0">
                {humanPageNumber}/{numberOfPages}
              </p>
            ) : null}
          </div>
          <div className="flex-even d-flex justify-content-end">
            {nextPagePath ? (
              <Link
                className="btn btn-sm btn-outline-dark fit-content"
                to={`/${nextPagePath}`}
              >
                {' '}
                Siguiente{' '}
              </Link>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  )
}

Pager.propTypes = {
  pageContext: PropTypes.object.isRequired
}
export default Pager
