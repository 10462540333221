import { useState } from 'react'

export const useForm = (initialState = {}) => {
  const [values, setValues] = useState(initialState)

  const reset = () => {
    setValues({
      ...initialState,
      event: null
    })
  }

  const handleInputChange = (props, evento = false) => {
    const { target } = props
   
    // Seteando los valores del target.value con JSON solo para los Eventos
    const eventValue = evento ? JSON.parse(target.value) : null

    // Si el evento exite uso eventValue
    if (evento) {
      let itemsEvent = []

      const nameEvent = target.name
      const events = values.event
      itemsEvent =  (events?.[`${nameEvent}`] == undefined) ? [] : events?.[`${nameEvent}`];

      const index = itemsEvent?.findIndex((i) => (i.code === eventValue.eventtia))

      if (index == -1) {
        setValues({
          ...values,
          event:{
            ...values.event,
            [nameEvent]: [...itemsEvent,{name: target.id, code: eventValue.eventtia, wiseCode: eventValue.wiseCode}]
          }
        })
      } else {
        itemsEvent.splice(index, 1)
        if (itemsEvent.length > 0) {
          setValues({
            ...values,
            event:{
              ...values.event,
              [nameEvent]: [...itemsEvent]
            }
          })
        } else {
          if(Object.values(values.event).length == 0){
            setValues({
              ...values,
              event: null
            })
          }else{
            delete values.event?.[`${nameEvent}`]
            setValues({
              ...values,
              event: {
                ...values.event,
              }
            })
          }
        }
      }
    } else {
      setValues({
        ...values,
        [target.name]: target.value
      })
    }
  }
  return [values, handleInputChange, reset]
}
